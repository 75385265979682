<template>
  <div class="pic">
    <el-image :src=url></el-image>
  </div>
</template>
<script>
export default {
    data() {
        return {
          url:'https://conference-dlhk.oss-cn-beijing.aliyuncs.com/'+window.sessionStorage.getItem("imgUrl")
        };
    }
};
</script>

<style>
.pic {
  width: 100%;margin: 40px auto;text-align: center;
}
</style>
